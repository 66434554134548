import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { Link } from "gatsby";
import pic from "../assets/images/level-of-detail.png";
import { logAnalytics } from "../firebase";
import { ACTIVE_PAGE } from "../components/nav/utils";
import styled from "@emotion/styled";
import { breakpoint } from "../components/styled-components/Breakpoints/breakpoints";

export default function LevelOfDetailPage() {
  const lod200 = 'https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Flevel-of-detail%2F1.png?alt=media&token=23028d73-d18b-4359-a554-f0d15b87df6c';
  const lod300 = 'https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Flevel-of-detail%2F2.png?alt=media&token=d909885e-3333-4d9c-96f3-0b44e4c4e4b8'
  const lod350 = 'https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Flevel-of-detail%2F3.png?alt=media&token=4e82b61d-681a-40ac-95ba-0a29e5350e65'

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.LEVELOFDETAIL}>
      <Seo title="Level of Development | Robotic Imaging" />

      <header className="site__header"></header>

      <Content>
        <Container>
          <h1>Level of Development</h1>
          <ImgRow>
            <img src={lod200} />
            <img src={lod300} />
            <img src={lod350} />
          </ImgRow>
          <p>
            Scan-to-BIM services bridge the gap between the physical and digital. At Robotic Imaging, we are skilled at converting point cloud data into detailed 3D models, streamlining the renovation, refurbishment, or expansion of existing structures. With Robotic Imaging, we offer various Levels of Development (LOD), from LOD 200 to 350, guaranteeing precision and clarity at every phase of your construction project. Unless directed otherwise, deliverables will be modeled at a minimum detail level of 200 (LOD 200). The LOD standards listed below are derived from the AIA's LOD specifications and may be subject to change depending on project requirements.
          </p>

          <Divider />

          <h1>LOD 200</h1>
          <LodRow>
            <img src={lod200} />

            <LodTextContainer>
              <LodHeader>At a Glance:</LodHeader>
              <span>Level of Geometry - Adequate</span>
              <span>Level of Information - Minimal</span>
              <span>Level of Accuracy - Adequate</span>

              <LodHeader>In-Depth Look:</LodHeader>
              <p>
                LOD 200 represents an initial stage for any Revit model, focusing on a more broad visualization of a building. This stage employs generalized models with rough geometries and sizes, emphasizing the overall structure and spatial interactions over detailed specifications. Elements like walls, basic structure, and key systems are depicted as symbolic placeholders, setting the stage for major project decisions related to layout and scope without delving into any construction details. At LOD 200, the model elements' Level of Geometry (LOG) for a specific object is graphically represented with enough detail to identify the object in terms of quantity, size, shape, location, and orientation.
              </p>
              <p>
                For example, a wall might be represented as a simple plane or extrusion with approximate thickness, but without material layers or structural details. It is most suitable for early cost estimations and layout planning. Thus, LOD 200 acts as a precursor to more detailed BIM stages, offering a foundational framework that informs further development with increased accuracy and detail.
              </p>
              <p>
                The Level of Information (LOI) for the object may not be included at LOD 200 and any information derived from model elements at this LOD must be considered approximate. The Level of Accuracy for the object would follow a tolerance of +/- 1/4” of deviation to the point cloud, but smaller components within the object may not be represented.
              </p>
            </LodTextContainer>
          </LodRow>

          <Divider />
          
          <h1>LOD 300</h1>
          <LodRow>
            <MobileImg src={lod300} />

            <LodTextContainer>
              <LodHeader>At a Glance:</LodHeader>
              <span>Level of Geometry - Precise</span>
              <span>Level of Information - Adequate</span>
              <span>Level of Accuracy - Precise</span>

              <LodHeader>In-Depth Look:</LodHeader>
              <p>
                With LOD 300, detailed accuracy is paramount. At this stage, BIM elements are depicted with precision, reflecting their specific construction systems, assemblies, and components in terms of size, shape, location, and orientation. Elements are positioned accurately in the model, but specific construction-related connections (like bolts, welds, or anchors) are often not fully defined. The dimensions are precise enough to be relied upon for coordination and general construction layouts. The Level of Information (LOI) would represent general attributes, properties, and/or parameters related to the object and its function.
              </p>
              <p>
                At this LOD, it's clear to understand where and how each component fits within the overall project's scope. The models can be enriched with specifications and other technical data, thereby facilitating comprehensive integration and coordination across different construction disciplines. This is available upon request with our BIM specialists. LOD 300's granularity ensures that the project stakeholders have a reliable and detailed digital blueprint to work with. The Level of Accuracy for the object would follow a tolerance of +/- 1/4” of deviation to the point cloud and smaller components within the object may start to be represented.
              </p>
            </LodTextContainer>

            <DesktopImg src={lod300} />
          </LodRow>

          <Divider />

          <h1>LOD 350</h1>
          <LodRow>
            <img src={lod350} />

            <LodTextContainer>
              <LodHeader>At a Glance:</LodHeader>
              <span>Level of Geometry - Exact</span>
              <span>Level of Information - Precise</span>
              <span>Level of Accuracy - Exact</span>

              <LodHeader>In-Depth Look:</LodHeader>
              <p>
                At LOD 350, models incorporate a high level of detail. Model elements and components are graphically represented within the model as a specific system, object, or assembly in terms of quantity, size, shape, location, orientation, and interfaces with other building systems. The Level of Information (LOI) would represent the actual attributes, properties, and/or parameters related to the object and any connected systems and their functions.
              </p>
              <p>
                LOD 350 begins to detail how components interact and connect with adjacent elements. For example, for a steel column, you would see connection plates, bolts, and welds where it attaches to beams or footings. At this level, the focus is not only on representing each component accurately in terms of quantity, size, shape, location, orientation, but also on thoroughly detailing how these components interact with one another within the building's ecosystem. The representation of elements at LOD 350 extends into how these various components—be it HVAC systems, electrical conduits, plumbing, structural frames, or architectural finishes—interface with one another, detailing connections, junctions, and potential overlaps.
              </p>
              <p>
                The detailed nature of LOD 350 models means it can serve as a comprehensive asset management resource, providing all stakeholders with a clear, detailed view of the building's physical and functional characteristics. Ultimately, scan-to-BIM at LOD 350 significantly enhances the clarity, accuracy, and utility of the model, ensuring that it serves as an effective communication tool among all project stakeholders—architects, engineers, contractors, and facility managers—supporting a seamless transition from design through to construction and beyond. The Level of Accuracy for the object would follow a tolerance of +/- 1/4” of deviation to the point cloud, but higher tolerances could be included to represent the proper appearance.
              </p>
            </LodTextContainer>
          </LodRow>
        </Container>

        <Link
          className="btn btn--center"
          to="/rfp"
          onClick={() => logAnalytics("clicked_receive_quote")}
        >
          Digitize Your Project
        </Link>
      </Content>
    </SiteWrapper>
  );
}

const Content = styled('main')`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: calc(100% - 40px);
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 20px;

  background-color: white;
  color: black;
`

const Container = styled('div')`
  display: flex;
  flex-direction: column;

  width: 100%;

  > h1 {
    margin: auto;
  }

  > p {
    color: black;
    margin: 0px;
    padding: 10px;
  }

  span, p {
    font-size: 16px;
  }
`

const ImgRow = styled('div')`
  display: flex;
  width: 100%;

  ${breakpoint('medium-', `
    display: none;
  `)}

  > img {
    width: calc(100% / 3);
  }
`

const Divider = styled('div')`
  width: calc(100% - 40px);
  margin: 20px;
  border-top: 4px solid black;
`

const LodRow = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;

  > * {
    width: 50%;
  }

  ${breakpoint('medium-', `
    flex-direction: column;
    > * {
      width: 100%;
    }
  `)}
`

const LodTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  
  p {
    color: black;
    margin: 0;
    margin-bottom: 20px;
  }
`

const LodHeader = styled('div')`
  font-weight: bold;
  font-size: 24px;
  margin: 20px 0;
`

const DesktopImg = styled('img')`
  ${breakpoint('medium-', `
    display: none;
  `)}
`

const MobileImg = styled('img')`
  display: none;
  ${breakpoint('medium-', `
    display: block;
  `)}
`